/*************************************/
/*           COMPONENTS              */
/*************************************/
  
  
/*********** HEADER  **********/

.header-top {
    position:relative;
    background-color: #003057;
    background-image: url("../assets/blue-dots.png");
    background-repeat: repeat 0px 0px;
    width:100%;
    height: 20px;
    // font-size: calc(10px + 2vmin);
    color: white;
  }

.subheader {
    height:150px;
    width:100%;
    background: #01263A;
}

  
.App-logo {
    height: 50px;
    pointer-events: none;
    float: right;
    position:relative;
    top:50px;
}

.subheader-title {
    color:white;
    text-align: center;
    overflow-wrap: break-word;
    font-size:1.20em; 
    padding-top:55px;
    float:left;
}

  @media only screen and (max-width: 800px) {
    .subheader-title {
        width:100%;
        font-size:1.00em; 
    }
  }

/*********** DATAGRID COLUMN  **********/


.wrap-header {
  white-space: normal;
  word-wrap: break-word;
}