/*********** DEPENDENCIES + VARIABLES  **********/
//reset file/code

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; 
} 

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// $fontFamily: 'Oswald',Verdana, Tahoma, sans-serif, Verdana, Tahoma, sans-serif;
$fontFamily: 'Raleway', Tahoma, sans-serif;
$fontForm: 'Raleway', Tahoma, sans-serif;


//COLORS
$orange: #e86924;
$border-solid: #D0D2D3;
$color-white:#ffffff;
$color-primary: #9b59b6;
$color-danger: #e74c3c;
$color-blue: #0b2444;
$color-darkblue: #0b2444;
$color-lightblue:#85b7d9;

//COMPONENTS
@import "./scss/views.scss";
@import "./scss/components.scss";
@import "./scss/forms.scss";


/*********** OVERRIDES  **********/

MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone{
  max-height: fit-content!important;
}

.MuiDataGrid-cell {
    max-height:fit-content!important;
    overflow:auto;
    max-height: inherit;
    white-space: initial!important;
    line-height:16px!important;
    display:flex!important;
    align-items: center;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    >div{ //this is for renderCell component wrapped in div
    max-height: inherit;
    width: 100%;
    white-space: initial;
    line-height:16px;
    }
}


// given OnCellEdit its is an input type html which cant support multiline text will need a custom component to handle multiline
.MuiDataGrid-editInputCell {
  white-space: normal !important;
  word-wrap: break-word !important;
  height: 'fit-content !important';
}

.MuiOutlinedInput-root{
  border-radius:0px !important;
}


.MuiInputLabel-root{
  font-size: 14px !important;
}

.MuiDialogContentText-root {
  font-size: 14px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus{
  border-color:$color-lightblue !important;
  background:#fff;
  color:rgba(0,0,0,0.8);
  box-shadow: none;
}


.MuiButton-root .MuiButton-contained .MuiButton-containedPrimary .MuiButton-sizeMedium .MuiButton-containedSizeMedium .MuiButtonBase-root {
  border-radius: 0 !important;
}

.MuiCardMedia-root {
  object-fit: contain !important;
}

.title {
  font-family: $fontFamily;
  font-weight: 800;
  font-size: calc(10px + 1vmin);
  color: black;
}

.element-space-height {
  margin-top:25px;
  margin-bottom: 25px;
}

.element-space-width {
  margin-left:25px;
  margin-right: 25px;
}

